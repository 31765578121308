import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/Layout";
import { parag } from "../styles/global.module.css";
import { contactDiv, link, emailText } from "../styles/about.module.css";

// markup
const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <p className={parag}>
        I am a generative artist based in São Paulo, Brazil. My background is in
        engineering, but I've always had an inclination towards doing creative
        projects.
      </p>

      <p className={parag}>
        In 2019, while helping my daugther to build an Arduino-based project for
        a science fair, I discovered Processing.
      </p>

      <p className={parag}>
        Processing is a coding language mostly used by artists for creating
        interactive graphics, animations, and visualizations.
      </p>

      <p className={parag}>
        Diving deeper into Processing’s ecosystem, I stumbled into the world of
        generative art. It was like an epiphany for me. I was finally able to
        combine the exact sciences that I loved so much with creativity and art.
        Playing with randomness and chaos and seeing beauty emerge through
        shapes, colors and textures.
      </p>

      <p className={parag}>Please, find below my contact information:</p>
      <div className={contactDiv}>
        <StaticImage alt="Twitter icon" src="../images/icon_twitter.svg" />
        <a
          href="https://twitter.com/PauloSilvaGArt"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          @PauloSilvaGArt
        </a>
      </div>
      <div className={contactDiv}>
        <StaticImage alt="Instagram icon" src="../images/icon_instagram.svg" />
        <a
          href="https://www.instagram.com/paulosilvagenart/"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          @paulosilvagenart
        </a>
      </div>
      <div className={contactDiv}>
        <StaticImage alt="Email icon" src="../images/icon_email.svg" />
        <p className={emailText}>paulosilva.genart (at) gmail.com</p>
      </div>
    </Layout>
  );
};

export default AboutPage;
